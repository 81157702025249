import React from "react";
// import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

// import Comms from "./pages/Comms";
// import Panels from "./pages/Panels";
// import StaffPortal from "./pages/staffportal/StaffPortal";
// import Form from "./pages/Form";

function App() {
  return <div>test.</div>;
}

// function App() {
//   return (
//     <Router>
//       <Switch>
//         <Route path="/comms">
//           <Comms />
//         </Route>
//         <Route path="/panels">
//           <Panels />
//         </Route>

//         <Route path="/staffportal">
//           <StaffPortal />
//         </Route>

//         <Route path="/form">
//           <Form />
//         </Route>

//         <Route path="/">
//           <nav>
//             <ul>
//               <li>
//                 <Link to="/panels">Panels</Link>
//               </li>
//               <li>
//                 <Link to="/comms">Comms</Link>
//               </li>
//               <li>
//                 <Link to="/staffportal">Staff portal</Link>
//               </li>
//               <li>
//                 <Link to="/form">Form</Link>
//               </li>
//             </ul>
//           </nav>
//         </Route>
//       </Switch>
//     </Router>
//   );
// }

export default App;
